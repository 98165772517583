import Reminder from "./components/Reminder";

function App() {
  return (
    <div>
      <Reminder />
    </div>
  );
}

export default App;
